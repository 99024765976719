import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, navigate }  from 'gatsby'
import ArrowR from '@material-ui/icons/ArrowForward'
import { Scrollbars } from 'react-custom-scrollbars'
import Solution from '../components/Solution'

import Layout from '../components/Layout'
import ds from '../theme'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'

import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import Body1 from '../components/Body1'
import Body2 from '../components/Body2'
import { withSolutions } from '../components/SolutionProvider'
import Varejo from '../images/home/icons/varejo.svg'
import ServicosFinanceiros from '../images/home/icons/servicos_financeiros.svg'
import Ecommerce from '../images/home/icons/e-commerce.svg'
import Educacao from '../images/home/icons/educacao.svg'
import Governo from '../images/home/icons/governo.svg'
import Tecnlogia from '../images/home/icons/tecnologia.svg'
import Saude from '../images/home/icons/saude.svg'

import { Container, mq } from '../components/styles/Layout.styles'
import { css } from '@emotion/core'

import {  
  styledOtherProducts,
  header,
  faqSolutions,
  contentFaq
} from '../components/styles/OtherProducts.styles'
import {
  solutionContent
} from '../components/styles/InternalPage.styles'

const Products = ({
  title,
  onClick
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        padding: 24,
        backgroundColor: '#f9fafa',
        borderRadius: 16,
        transition: 'height 0.2s',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14)',
        cursor: 'pointer'
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: 23.7,
            fontWeight: 600,
            lineHeight: 'normal'
          }}
        >
          {title}
        </Body1>
        <div
          style={{
            marginLeft: 'auto'
          }}
        >
          <ArrowR 
            style={{
              color: 'rgba(0, 0, 0, 0.54)'
            }}
          />
        </div>
      </Div>
    </div>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledOtherProducts}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>Outros Produtos</span><br />
                Serviços adequados para você
              </H4>
              <Paragraph>Pensando sempre em oferecer os serviços mais adequados para a sua necessidade, também desenvolvemos outras soluções de Data Center para empresas de grande porte e mercados especializados.</Paragraph>
            </Column>
          </Row>
          <Row
            flexDirection={['column', 'column', 'row', 'row']}
            mt={30}
            pb={50}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              ml={[0, 0, 106, 208]}
            >
              <Div
                maxWidth={['100%', '100%', 422, 422]}
                style={{
                  marginBottom: ['16px']
                }}
              >
                <Products
                  title='Domínios'
                  onClick={() => navigate('/dominios')}
                />
                <Products
                  title='Disaster Recovery'
                  onClick={() => navigate('/disaster-recovery')}
                />
                <Products
                  title='Armazenamento remoto'
                  onClick={() => navigate('/armazenamento-remoto')}
                />
                <Products 
                  title='Proteção DDOS'
                  onClick={() => navigate('/protecao-ddos')}
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 1, 1/2]}
              mr={[0, 0, 106, 208]}
            >
              <Div
                maxWidth={['100%', '100%', 422, 422]}
              >
                <Products 
                  title='Certificados SSL'
                  onClick={() => navigate('/certificados-ssl/')}
                />
                <Products 
                  title='Hospedagem de Sites'
                  onClick={() => navigate('/hospedagem-de-sites')}
                />
                <Products 
                  title='Licenças'
                  onClick={() => navigate('/licencas')}
                />
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={faqSolutions}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            justifyContent='center'
            mt={40}
            mb={40}
            css={contentFaq}
          >
            <Column
              width={[1]}
            >
              <H4>Soluções Personalizadas</H4>
              <Paragraph>Nossa equipe de desenvolvimento de negócios escuta você, entende a sua estrutura e molda serviços sob medida para otimizar o seu setor de TI. O nosso maior objetivo é garantir a segurança de dados críticos para a sua empresa e soluções para permitir mais inovação no setor. Somos personalizados para sanar a sua dor.</Paragraph>
            </Column>
          </Row>
          <Row
            justifyContent='center'
            mb={[50, 40, 70, 70]}
          >
            <Div
              maxWidth={['100%', 422, 680]}
            >
              <Solution
                title='Serviços Financeiros'
                id='finan'
                src={ServicosFinanceiros}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>O mercado financeiro é o que mais tem investido em segurança no Brasil. Segundo pesquisada Febraban, em 2016 os investimentos chegaram a 18,6 bilhões de dólares no país.Empresas desse setor precisam garantir ao consumidor final o fator segurança e por isso,esse investimento está em curva de ascensão constante.</Body2>
                    <Body2 style={{ fontWeight: 500 }}>Mas sabemos que não é só investir em segurança, é garantir a excelência na entrega dassoluções.</Body2>
                    <Body2>Sabe por que a HostDime Brasil é o parceiro ideal para o seu negócio? Além de segurançafísica e de soluções, nós oferecemos benefícios que fazem a diferença no fim de cadaoperação: flexibilidade, maior disponibilidade, alta conectividade, velocidade nas transaçõese eficiência operacional.</Body2>
                    <Body2>Se, além de uma segurança proativa e robusta, você:</Body2>
                    <Body2>
                      <ul>
                        <li>- Precisa de um processamento contínuo para as suas transações;</li>
                        <li>- Sabe que uma alta conectividade é essencial para estar sempre disponível;</li>
                        <li>- Necessita de uma alta capacidade de armazenamento e backup de informações;</li>
                        <li>- Entende que precisa otimizar e reduzir a latência (tempo de resposta) de suaatividade;</li>
                      </ul>
                    </Body2>
                    <Body2>Então é com nossas soluções que criará uma vantagem estratégica no mercado.</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='E-commerce'
                id='ecom'
                src={Ecommerce}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>
                      Com um comércio online, estar online 24 horas por dia, 7 dias da semana, 365 dias do ano é
                      o básico que precisa ser garantido para a sua empresa. Sem falar que todo empresário
                      digital deve ser multi: entender de vendas, comunicação e até de TI, se necessário.
                      A gente sabe que não é uma rotina fácil, e estamos aqui para dar o suporte necessário,
                      principalmente nas datas comemorativas que o seu negócio não pode sair do ar.
                    </Body2>
                    <Body2>
                      Se você já conta com uma pessoa de TI para gerenciar seus servidores e o seu negócio, nós
                      podemos ser parceiros dele com um suporte disponível 24 x 7 x 365, e produtos
                      personalizados para a sua empresa, mas tão personalizados que vão ser ideais para otimizar
                      seus custos com toda essa operação.
                    </Body2>
                    <Body2>
                      E, se você ainda não tem "o carinha da informática" para te auxiliar, além das soluções
                      personalizadas e suporte proativo, podemos agregar nos seus serviços um gerenciamento
                      full.
                    </Body2>
                    <Body2>
                      Tudo depende do que você precisar, quando precisar.
                      Se você quer disponível 100% do tempo para todos os seus clientes, sem precisar resolver
                      problemas simples de TI, então nós estamos aqui, 100% disponíveis e personalizados para
                      você.
                    </Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='Educação'
                id='edu'
                src={Educacao}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>
                      Um pensamento que ocorre constantemente quando citamos a junção de "tecnologia" e
                      "educação", é a oferta cada vez maior de cursos por Ensino à Distância (EaD). Mas é
                      essencial lembrar que a tecnologia já está muito bem estruturada em outras vertentes da
                      educação, seja por aulas mais interativas, através dos tablets de ensino, ou até mesmo pelo
                      sistema online das escolas e faculdades, em que são ofertadas matrículas e o serviço de
                      acompanhamento de todo o histórico de desempenho do aluno.
                    </Body2>
                    <Body2>
                      Para criar o ambiente propício na integração de sistemas, redes e na manutenção desses
                      dados, é necessário contar com um parceiro estratégico que garanta integridade de todos
                      esses dados e facilidade de acesso a eles.
                    </Body2>
                    <Body2>
                      Pensando nisso, é importante que no planejamento de sua instituição esteja previsto
                      soluções para lidar com:
                    </Body2>
                    <Body2>
                      <ul>
                        <li>- Conectividade e disponibilidade de informação;</li>
                        <li>- Flexibilidade de recursos para lidar com a sazonalidade de acessos (período de matrículas ou finais de períodos);</li>
                        <li>- Manutenção de sistemas críticos de gestão (como ERP);</li>
                      </ul>
                    </Body2>
                    <Body2>Se identificou com tudo isso? Conte conosco para manter seu sistema ativo e seus recursos
                      flexíveis, otimizando seus custos com toda essa estrutura.</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='Governo'
                id='gov'
                src={Governo}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>Em períodos de cortes do Governo e a obrigação de planejar com 1 ano de antecedência
                      todos os investimentos em TICs, é necessário aplicar verbas de forma inteligente e tentar
                      concentrar a maior parte de soluções necessárias de TI em um ambiente que ofereça melhor
                      custo/benefício para a instituição.</Body2>
                    <Body2>Somos ideais porque, além de infraestrutura robusta e segura, somos também solução,
                      suporte e otimização.</Body2>
                    <Body2>Não basta apenas armazenar dados críticos, mas também é necessário - através do
                      princípio da transparência - disponibilizá-los com rapidez e prontidão e incentivar, através
                      desta e de outras ações, a inovação do país não só no setor privado, como também em
                      nossos orgãos públicos.</Body2>
                    <Body2>Segundo o Secretário de Governo Digital, Luis Felipe Monteiro, a expectativa é que a
                      economia digital incremente até 5,7% no PIB. “ ​ Queremos desenvolver no país uma
                      economia digital pulsante, dinâmica e tratada de maneira responsável e cuidadosa,
                      protegendo a privacidade dos usuários”, completou. *</Body2>
                    <Body2>*informações retiradas de matéria de assessoria do Governo Federal
                      http://www.economia.gov.br/noticias/2019/02/secretario-de-governo-digital-destaca-relaca
                      o-direta-entre-transformacao-digital-e-avancos-sociais</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='Varejo'
                id='var'
                src={Varejo}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>Quando o sistema local do varejo falha, o prejuízo é certo.</Body2>
                    <Body2>Imagine uma grande distribuidora sem controle de estoque, controle de faturamento e
                      despesa com fornecedores. A imagem quem vem à sua cabeça é caótica, né? Isso por que o
                      varejo é um dos setores que mais sentem o impacto da tecnologia para o desenvolvimento e
                      otimização de suas atividades.</Body2>
                    <Body2>Conectividade, disponibilidade e agilidade são um diferencial para o setor. E nós podemos
                      garantir um serviço que garanta tudo isso através de infraestrutura robusta e soluções
                      personalizadas para o seu negócio.</Body2>
                    <Body2>Nosso orçamento é consultivo: o time de desenvolvimento de negócios vai te escutar,
                      entender o que já existe de estrutura de TI em seu negócio e vai sugerir formas de
                      otimizá-la para garantir uma otimização de seus custos.</Body2>
                    <Body2>Temos desde a opção de Colocation, possibilitando alocar equipamentos para a nossa
                      estrutura e garantir uma vida útil maior de todo o equipamento investido. Temos também a
                      contratação de serviços dedicados, evitando a preocupação com reposição e manutenção de
                      hardwares e temos o serviço de cloud server, para garantir ainda mais flexibilidade para o
                      seu negócio.</Body2>
                    <Body2>Tudo isso com o suporte técnico verdadeiramente 24 x 7 x 365, acompanhando ativamente
                      as suas necessidades.</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='Saúde'
                id='saud'
                src={Saude}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>Com um comércio online, estar online 24 horas por dia, 7 dias da semana, 365 dias do ano é
                      o básico que precisa ser garantido para a sua empresa. Sem falar que todo empresário
                      digital deve ser multi: entender de vendas, comunicação e até de TI, se necessário.
                      A gente sabe que não é uma rotina fácil, e estamos aqui para dar o suporte necessário,
                      principalmente nas datas comemorativas que o seu negócio não pode sair do ar.</Body2>
                    <Body2>Se você já conta com uma pessoa de TI para gerenciar seus servidores e o seu negócio, nós
                      podemos ser parceiros dele com um suporte disponível 24 x 7 x 365, e produtos
                      personalizados para a sua empresa, mas tão personalizados que vão ser ideais para otimizar
                      seus custos com toda essa operação.</Body2>
                    <Body2>E, se você ainda não tem "o carinha da informática" para te auxiliar, além das soluções
                      personalizadas e suporte proativo, podemos agregar nos seus serviços um gerenciamento
                      full.</Body2>
                    <Body2>Tudo depende do que você precisar, quando precisar.
                      Se você quer disponível 100% do tempo para todos os seus clientes, sem precisar resolver
                      problemas simples de TI, então nós estamos aqui, 100% disponíveis e personalizados para
                      você.</Body2>
                    <Body2>Nossos servidores são um grande cérebro de operações, mas sabemos que eles são,
                      também, o coração de toda a inovação que a saúde tem abraçado.</Body2>
                    <Body2>Modernização da infraestrutura de hospitais não está apenas nos equipamentos de exames
                      de imagem ou facilidade nos processos laboratoriais. Também está na adoção de prontuário
                      eletrônico, investimentos em rede, virtualização e mais.</Body2>
                    <Body2>A tecnologia tem sido um fator definitivo para reduzir custos dos hospitais, conectar
                      médicos que buscam uma eficiência e assertividade nos diagnósticos e para agilizar e
                      humanizar o processo de atendimento ao paciente, que vai poder ter acesso a informações
                      de seus exames e a seu histórico médico com cada vez mais facilidade.</Body2>
                    <Body2>Somos pioneiros no desenvolvimento de soluções para o e-SUS, sistema de prontuários
                      eletrônicos pelo serviço público de saúde. E somos apaixonados por todas as possibilidades
                      que a inclusão e familiarização da tecnologia na saúde podem trazer para beneficiar a todos:
                      desde gestores de clínicas, médicos até os pacientes.</Body2>
                    <Body2>Nossas soluções são como diagnósticos: investigadas, pensadas e arquitetadas de forma
                      personalizada para cada caso.</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
              <Solution
                title='Tecnologia'
                id='tech'
                src={Tecnlogia}
              >
                <Scrollbars
                  style={{ height: 400 }}
                >
                  <Div
                    css={solutionContent}
                  >
                    <Body2>Para todos aqueles profissionais do setor de tecnologia que buscam terceirizar não apenas
                      infraestrutura, mas também soluções que vão otimizar o tempo gasto em resoluções
                      cotidianas de TI para aplicação de inovação e melhorias no setor, nós somos o parceiro
                      certo!</Body2>
                    <Body2>Queremos que você seja o protagonista das soluções inovadoras e para que isso aconteça,
                      somos o seu backup ideal: oferecemos uma infraestrutura robusta, flexível e disponível com
                      suporte 24 x 7 x 365.</Body2>
                    <Body2>Além disso, ainda possuímos soluções personalizadas. Entendemos, de forma consultiva, o
                      que já está disponível de equipamento, infraestrutura e solução e planejamos, juntos uma
                      mesclagem ou a transferência de serviços para a nossa estrutura.</Body2>
                    <Body2>Tudo pensando no que for melhor para você e seu bolso.</Body2>
                  </Div>
                </Scrollbars>
              </Solution>
            </Div>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo 
        title='Outros produtos | Soluções de Data Center'
        description='Encontre as soluções de Data Center mais adequadas à sua necessidade. Otimize o gerenciamento de seus ativos de TI.'
      />
      <PageTemplate
        title='Outros Produtos'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "img-step-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
